var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { CaseService, OrgService, ContractService } from './api/api';
import { Router } from '@angular/router';
import { NbTokenService, NbTokenStorage } from '@nebular/auth';
import { UserService } from './users.service';
import { IntakeFlowService } from '../utils/intake-flow.service';
import { take, map, retry, retryWhen, tap } from 'rxjs/operators';
import { CounselorService } from './counselor.service';
import { ContentManagementService } from './contentManagement.service';
import { upsertItem } from '../utils/upsertItem';
import { AnalyticsService } from '../utils/analytics.service';
import { NbSpinnerService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { IN_PERSON_UPDATE_MODE, SWITCH_COUNSELORS_UPDATE_MODE, ONLINE_UPDATE_MODE, AUTHORIZATION_UPDATE_MODE, SWITCH_PROVIDER, SWITCH_TO_IN_PERSON, SWITCH_TO_ONLINE, COUNSELING_LOCATION_IN_OFFICE, SWITCH_VID_TO_ONLINE, BH_MODE_OF_HELP, ASSIGN_TO_PROVIDER, ASSIGN_TO_COACH, ASSIGN_TO_CALL_CENTER, SELECT_COUNSELOR_MODES_OF_HELP, SWITCH_TO_PHYSICIAN_UPDATE_MODE, SWITCH_TO_PHISICIAN, } from '../utils/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@nebular/auth/services/token/token-storage";
import * as i3 from "@nebular/auth/services/token/token.service";
import * as i4 from "./users.service";
import * as i5 from "./api/case.service";
import * as i6 from "./api/contract.service";
import * as i7 from "./api/org.service";
import * as i8 from "../utils/intake-flow.service";
import * as i9 from "./counselor.service";
import * as i10 from "./contentManagement.service";
import * as i11 from "../utils/analytics.service";
import * as i12 from "@nebular/theme/services/spinner.service";
import * as i13 from "ngx-cookie-service/cookie-service/cookie.service";
var REGISTRATION_ROUTE = ['/auth/register'];
var DASHBOARD_ROUTE = ['/pages/dashboard'];
var ERROR_ROUTE = ['/pages/error'];
var MAX_ATTEMPTS = 3;
var retryAttempts = 0;
var EMPLOYEE_NUMBER_COMPANY_CODE = environment.employeeNumberCompanyCode;
var hasNoSuggestionsForCounseling = function (_a) {
    var modeOfHelp = _a.modeOfHelp, providers = _a.providers, internalCase = _a.internalCase;
    return modeOfHelp === 'counselor' &&
        providers.length === 0 &&
        !internalCase.providerId;
};
var ɵ0 = hasNoSuggestionsForCounseling;
var externalIdMissing = function (errors) {
    return errors.pipe(tap(function (error) {
        // This is caused when there is a network latency while calling external APIs.
        // User may be in an inconsistent / incomplete state.
        // retry only 3 times to prevent continuous hits to API if its down
        retryAttempts++;
        if ((error && error.message !== 'Missing InternalId') ||
            retryAttempts === MAX_ATTEMPTS) {
            retryAttempts = 0;
            throw error;
        }
    }));
};
var ɵ1 = externalIdMissing;
var CaseManagementService = /** @class */ (function () {
    function CaseManagementService(router, tokenStorage, tokenService, userService, caseService, contractService, orgService, intakeFlowService, providerService, contentMgmtService, analyticsService, spinner, cookieService) {
        this.router = router;
        this.tokenStorage = tokenStorage;
        this.tokenService = tokenService;
        this.userService = userService;
        this.caseService = caseService;
        this.contractService = contractService;
        this.orgService = orgService;
        this.intakeFlowService = intakeFlowService;
        this.providerService = providerService;
        this.contentMgmtService = contentMgmtService;
        this.analyticsService = analyticsService;
        this.spinner = spinner;
        this.cookieService = cookieService;
        this.fetching = false;
        this.fetchingUser = false;
        this.fetchingAppointment = false;
        this.fetchingOrg = false;
        this.fetchingParentOrg = false;
        this.fetchingContract = false;
        this.fetchingCounselors = false;
        this.specialBenefits = new BehaviorSubject([]);
        this.creatingCase = false;
        this.ssoCodeBlock = false;
        this.openCasesSubject = new BehaviorSubject([]);
        this.userSubject = new BehaviorSubject(undefined);
        this.appointmentSubject = new BehaviorSubject(undefined);
        this.appointmentsSubject = new BehaviorSubject([]);
        this.counselorsSubject = new BehaviorSubject([]);
        this.onSiteCounselorsSubject = new BehaviorSubject([]);
        this.orgSubject = new BehaviorSubject(undefined);
        this.parentOrgSubject = new BehaviorSubject(undefined);
        this.contractSubject = new BehaviorSubject(undefined);
        this.allProvidersSubject = new BehaviorSubject([]);
        this.loginStatusChangeSubject = new ReplaySubject(1);
        this.ngOnInit();
    }
    CaseManagementService.prototype.upsertProviders = function (result) {
        throw new Error('Method not implemented.');
    };
    CaseManagementService.prototype.getLoginStatus = function () {
        return this.loginStatusChangeSubject;
    };
    CaseManagementService.prototype.getLoginStatusFromToken = function () {
        return this.tokenStorage.get().isValid();
    };
    CaseManagementService.prototype.getOpenCases = function () {
        return this.openCasesSubject.asObservable();
    };
    CaseManagementService.prototype.getUser = function () {
        return this.userSubject.asObservable();
    };
    CaseManagementService.prototype.getCounselors = function () {
        return this.counselorsSubject.asObservable();
    };
    CaseManagementService.prototype.getOrg = function () {
        return this.orgSubject.asObservable();
    };
    CaseManagementService.prototype.getParentOrg = function () {
        return this.parentOrgSubject.asObservable();
    };
    CaseManagementService.prototype.getContract = function () {
        return this.contractSubject.asObservable();
    };
    CaseManagementService.prototype.getProviders = function () {
        return this.allProvidersSubject.asObservable();
    };
    /**
     * gets the last created case
     */
    CaseManagementService.prototype.getLastCase = function () {
        // sort the list and return the first one... 0 dereferencing will give undefined when the list is empty
        return this.openCasesSubject.pipe(map(function (list) { return list[0]; }));
    };
    CaseManagementService.prototype.getProvider = function (id) {
        return this.counselorsSubject.pipe(map(function (list) { return list.find(function (x) { return x.id === id; }); }));
    };
    CaseManagementService.prototype.getSessionById = function (id) {
        return this.appointmentsSubject.pipe(map(function (appointments) { return appointments.find(function (a) { return a.id === id; }); }));
    };
    CaseManagementService.prototype.getProviderOn = function (model) {
        if (model.internalCase) {
            return this.counselorsSubject.pipe(map(function (list) {
                return list.find(function (x) {
                    return x.internalId === model.internalCase.providerId ||
                        x.internalId === model.providerId;
                });
            }));
        }
        return of(null);
    };
    CaseManagementService.prototype.getSuggestions = function (model) {
        return this.counselorsSubject.pipe(map(function (list) { return list.filter(function (x) { return model.providers.includes(x.internalId); }); }));
    };
    CaseManagementService.prototype.spinUp = function (method) {
        this.spinner.registerLoader(method.catch(function (err) { return null; }));
    };
    CaseManagementService.prototype.ngOnInit = function () {
        var _this = this;
        this.openCasesSubject.subscribe(function (models) {
            if (models) {
                _this.refreshCounselors();
            }
            else {
                _this.openCasesSubject.next([]);
            }
        });
        this.validWorkLifeBenefits = this.getContract()
            .pipe(filter(function (m) { return !!m; }), tap(function (m) {
            return _this.specialBenefits.next([
                (m.allowedSessions === 99 ? 'Unlimited' : m.allowedSessions) + " Counseling sessions",
            ]);
        }), map(function (m) { return m.validWorkLifeBenefits; }), take(1))
            .toPromise();
        this.getLastCase().subscribe(function (model) {
            if (model) {
                // when no counselor suggestions are made on the last counseling case, then remove all the others
                var cases = _this.openCasesSubject.getValue();
                var onlyOneWithoutProviders = cases.filter(function (obj, index) { return index === 0 || !hasNoSuggestionsForCounseling(obj); });
                // when we actually removed items then update the open cases list
                if (onlyOneWithoutProviders.length < cases.length) {
                    // we return since there is no need to refresh until this triggers all the observers again (including last case)
                    _this.openCasesSubject.next(onlyOneWithoutProviders);
                    return;
                }
            }
        });
        this.userSubject.subscribe(function (user) {
            if (user) {
                // Get cases and set Org, Contract info
                _this.refreshOrg(user.orgId); // The orgId of the contract associated with the chosenSiteLocation
                _this.refreshParentOrg(user.orgId); // The orgId of the contract associated with the chosenSiteLocation
                _this.refreshContract(user);
                if (user.ssoUser && !user.profileComplete) {
                    _this.appointmentSubject.next(null);
                    _this.openCasesSubject.next([]);
                }
            }
            else {
                _this.appointmentSubject.next(null);
                _this.openCasesSubject.next([]);
            }
        });
        this.tokenService.tokenChange().subscribe(function (token) {
            if (token.isValid()) {
                _this.refreshUser()
                    .then(function () {
                    _this.refreshOpenCases();
                })
                    .catch(function (err) {
                    throw err;
                });
            }
            else {
                _this.userSubject.next(null);
            }
        });
    };
    /**
     * Navigates the user to registration screen when unauthenticated
     * Creates the case from the intake flow data and moves to the dashboard when authenticated
     */
    CaseManagementService.prototype.registerOrCreateCase = function (itmsCaseData, redirect) {
        if (itmsCaseData === void 0) { itmsCaseData = null; }
        if (redirect === void 0) { redirect = true; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var caseData, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ssoCodeBlock = false;
                        if (this.creatingCase) {
                            return [2 /*return*/];
                        }
                        if (!itmsCaseData) return [3 /*break*/, 1];
                        caseData = itmsCaseData;
                        return [3 /*break*/, 3];
                    case 1:
                        caseData = this.intakeFlowService.getFormData();
                        if (!caseData.riskAtIntake) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUser().pipe(take(1)).toPromise()];
                    case 2:
                        user = _a.sent();
                        if (user) {
                            this.userService
                                .saveRiskHistory({
                                userId: user.id,
                                type: 'risk',
                                value: caseData.riskAtIntake,
                                ownerId: user.id,
                                updatedBy: user.id,
                            })
                                .subscribe();
                        }
                        _a.label = 3;
                    case 3:
                        // when the user is authenticated (having a valid token) we try to create the case, then move to dashboard
                        if (this.tokenStorage.get().isValid()) {
                            // check to see if user profile completed
                            return [2 /*return*/, new Promise(function (resolveIt, rejectIt) {
                                    try {
                                        _this.userService
                                            .getSsoUser()
                                            .pipe(take(1))
                                            .subscribe(function (user) {
                                            _this.userSubject.next(user.data);
                                            if (!!user.data.profileComplete === false &&
                                                !!user.data.internalId === false) {
                                                _this.router.navigate(REGISTRATION_ROUTE); // user can register or login at the end
                                            }
                                            else {
                                                _this.creatingCase = true;
                                                _this.caseService
                                                    .createCase(caseData)
                                                    .pipe(take(1))
                                                    .subscribe(function (newCase) {
                                                    // Clean cases
                                                    upsertItem([newCase], _this.openCasesSubject); // upserting item
                                                    _this.validWorkLifeBenefits.then(function (value) {
                                                        _this.contentMgmtService.getAllRecommendedContent([newCase.id], value);
                                                    });
                                                    // 'Counseling Recommendation' GA
                                                    if (newCase.providers) {
                                                        _this.analyticsService.trackRecommendations('Counseling Recommendation', newCase.providers.length);
                                                    }
                                                    var modeOfHelp = _this.intakeFlowService.modeOfHelp;
                                                    _this.intakeFlowService.resetFormData();
                                                    if (redirect) {
                                                        _this.refreshUser()
                                                            .then(function () {
                                                            _this.creatingCase = false;
                                                            // Navigate to dashboard
                                                            if (SELECT_COUNSELOR_MODES_OF_HELP.includes(modeOfHelp)) {
                                                                _this.router.navigate([
                                                                    "/pages/counselor-faceted-search/" + newCase.id,
                                                                ]);
                                                            }
                                                            else {
                                                                // Navigate to dashboard
                                                                _this.router.navigate(DASHBOARD_ROUTE);
                                                            }
                                                        })
                                                            .catch(function (err) {
                                                            _this.creatingCase = false;
                                                            throw err;
                                                        });
                                                    }
                                                    _this.creatingCase = false;
                                                    resolveIt(newCase);
                                                }, function (err) {
                                                    _this.creatingCase = false;
                                                    _this.router.navigate(ERROR_ROUTE); // failed... maybe token expired, user can register or login
                                                });
                                            }
                                        });
                                    }
                                    catch (error) {
                                        rejectIt("Error during case creation: " + error);
                                    }
                                })];
                        }
                        else {
                            this.router.navigate(REGISTRATION_ROUTE); // user can register or login at the end
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CaseManagementService.prototype.completeCreateCase = function () {
        var _this = this;
        if (this.creatingCase)
            return;
        // get the intake data that we will try to create the case from
        var caseData = this.intakeFlowService.getFormData();
        // when the user is authenticated (having a valid token) we try to create the case, then move to dashboard
        if (this.tokenStorage.get().isValid() &&
            this.intakeFlowService.isFormValid()) {
            this.creatingCase = true;
            this.caseService
                .createCase(caseData)
                .pipe(take(1))
                .subscribe(function (obj) {
                upsertItem([obj], _this.openCasesSubject); // upserting item
                _this.validWorkLifeBenefits.then(function (value) {
                    _this.contentMgmtService.getAllRecommendedContent([obj.id], value);
                });
                // 'Counseling Recommendation' GA
                if (obj.providers) {
                    _this.analyticsService.trackRecommendations('Counseling Recommendation', obj.providers.length);
                }
                _this.router.navigate(DASHBOARD_ROUTE);
                _this.intakeFlowService.resetFormData();
                _this.creatingCase = false;
            }, function (err) {
                _this.creatingCase = false;
                return err;
            });
        }
    };
    CaseManagementService.prototype.refreshOpenCases = function () {
        var _this = this;
        var user = this.userSubject.getValue();
        if (!user || this.fetchingOpenCases)
            return;
        this.validWorkLifeBenefits.then(function (value) {
            _this.contentMgmtService.getAllRecommendedContent([], value);
        });
        this.fetchingOpenCases = true;
        var sub = this.caseService
            .findOpenCases()
            .pipe(retry(3))
            .subscribe(function (data) {
            _this.fetchingOpenCases = false;
            _this.openCasesSubject.next(data.sort(function (a, b) {
                return new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime();
            }));
        }, function (err) {
            _this.fetchingOpenCases = false;
            _this.openCasesSubject.error(err);
        });
        if (!this.getLoginStatusFromToken()) {
            this.fetchingOpenCases = false;
            sub.unsubscribe();
        }
    };
    CaseManagementService.prototype.refreshOpenCasesOnly = function () {
        var _this = this;
        var user = this.userSubject.getValue();
        if (!user || this.fetchingOpenCases)
            return;
        this.fetchingOpenCases = true;
        var sub = this.caseService
            .findOpenCases()
            .pipe(retry(3))
            .subscribe(function (data) {
            _this.fetchingOpenCases = false;
            _this.openCasesSubject.next(data.sort(function (a, b) {
                return new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime();
            }));
        }, function (err) {
            _this.fetchingOpenCases = false;
            _this.openCasesSubject.error(err);
        });
        if (!this.getLoginStatusFromToken()) {
            this.fetchingOpenCases = false;
            sub.unsubscribe();
        }
    };
    CaseManagementService.prototype.getRemoteCases = function () {
        return this.caseService.findOpenCases();
    };
    CaseManagementService.prototype.refreshUser = function () {
        var _this = this;
        if (this.fetchingUser)
            return;
        this.fetchingUser = true;
        return this.userService
            .getAllUserData()
            .pipe(retryWhen(externalIdMissing), tap(function (data) {
            _this.fetchingUser = false;
            _this.userSubject.next(data.data);
        }, function (err) {
            _this.fetchingUser = false;
            _this.userSubject.next(null);
            _this.router.navigate(ERROR_ROUTE);
        }))
            .toPromise();
    };
    CaseManagementService.prototype.refreshCounselors = function () {
        var _this = this;
        var model = this.openCasesSubject.getValue().filter(function (m) { return m.modeOfHelp && m.modeOfHelp !== 'coaching'; });
        var exist = this.counselorsSubject
            .getValue()
            .map(function (provider) { return provider.id; });
        if (!model || !model.length)
            return;
        // collect all the ids of the providers we have not yet fetched
        var unseen = function (id) { return id && !exist.includes(id); };
        // look for either the assigned provider or the list of suggestions
        var union = function (data) {
            var providers;
            if (data.internalCase && data.internalCase.authorizationId) {
                providers = [data.internalCase.providerId];
            }
            else {
                providers = data.providers;
            }
            return providers;
        };
        var ids = []
            .concat.apply([], model.map(function (caseData) { return union(caseData) || []; })).filter(unseen);
        // when there is no counselor to fetch or we are already fetching... then just stop
        if (!ids.length || this.fetchingCounselors)
            return;
        this.fetchingCounselors = true;
        this.providerService
            .getCounselorsAPI(ids)
            .subscribe(function (data) {
            _this.fetchingCounselors = false;
            upsertItem(data, _this.counselorsSubject); // just insert the ones we got
        }, function (err) {
            _this.fetchingCounselors = false;
            _this.counselorsSubject.error(err);
        });
    };
    CaseManagementService.prototype.refreshOrg = function (orgId) {
        var _this = this;
        if (!orgId || this.fetchingOrg)
            return;
        this.fetchingOrg = true;
        this.orgService.getOrgId(orgId).subscribe(function (data) {
            if (data.userDefinedTextField10) {
                _this.cookieService.set('BhCode', data.userDefinedTextField10);
            }
            _this.fetchingOrg = false;
            _this.orgSubject.next(data);
        }, function (err) {
            _this.fetchingOrg = false;
            _this.orgSubject.next(null);
        });
    };
    CaseManagementService.prototype.refreshParentOrg = function (orgId) {
        var _this = this;
        if (!orgId || this.fetchingParentOrg)
            return;
        this.fetchingParentOrg = true;
        this.orgService.getOrgId(orgId).subscribe(function (data) {
            _this.fetchingParentOrg = false;
            if (!_this.cookieService.get('BhCode') && data.userDefinedTextField10) {
                _this.cookieService.set('BhCode', data.userDefinedTextField10);
            }
            if (data.parentOrg === '00000000-0000-0000-0000-000000000000') {
                _this.cookieService.set('parentCompany', data.name);
                _this.parentOrgSubject.next(data);
            }
            else {
                _this.refreshParentOrg(data.parentOrg);
            }
        }, function (err) {
            _this.fetchingParentOrg = false;
            _this.parentOrgSubject.next(null);
        });
    };
    CaseManagementService.prototype.refreshContract = function (model) {
        var _this = this;
        if (!model || !model.orgId || this.fetchingContract)
            return;
        // set employer information when login
        if (model.employerInformation) {
            var employerInfo = model.employerInformation;
            this.analyticsService.setAffiliation(employerInfo.companyAffiliation);
            this.analyticsService.setCompany(employerInfo.companyName);
            this.analyticsService.setLocation(employerInfo.companySiteLocation);
        }
        this.fetchingContract = true;
        this.contractService.getOrgContractById(model.orgId).subscribe(function (data) {
            _this.fetchingContract = false;
            if (data) {
                data.validWorkLifeBenefits = data.validWorkLifeBenefits.filter(function (benefits) { return benefits.split(':')[0] !== 'Emergency Services'; });
                _this.contractSubject.next(data);
            }
            else {
                _this.contractSubject.next(null);
            }
        }, function (err) {
            _this.fetchingContract = false;
            _this.contractSubject.error(err);
        });
    };
    CaseManagementService.prototype.getContractOfNearestParent = function (orgList, org) {
        var parent = org.parentOrg;
        if (parent === '00000000-0000-0000-0000-000000000000') {
            return orgList[org.id].contract;
        }
        else if (orgList[parent].contract) {
            return orgList[parent].contract;
        }
        else {
            var grandParentOrgId = orgList[parent].org.parentOrg;
            var grandParent = orgList[grandParentOrgId].org; // : orgList['parentOrg'].org;
            return this.getContractOfNearestParent(orgList, grandParent);
        }
    };
    CaseManagementService.prototype.assignToProvider = function (provider, fromModal) {
        var _this = this;
        if (fromModal === void 0) { fromModal = false; }
        // this.selectedCase.internalCase.providerId = provider.internalId;
        this.caseService.getCaseById(this.selectedCase.id).subscribe(function (cases) {
            if (!cases) {
                return;
            }
            _this.selectedCase = cases;
            _this.selectedCase.updateMode = AUTHORIZATION_UPDATE_MODE;
            // !!provider ? this.selectedCase.internalCase.providerId = provider.internalId : null;
            if (provider) {
                _this.selectedCase.internalCase.providerId = provider.internalId;
            }
            _this.updateCase(_this.selectedCase, ASSIGN_TO_PROVIDER, fromModal);
        });
    };
    CaseManagementService.prototype.assignToCoach = function (provider, fromModal) {
        if (fromModal === void 0) { fromModal = false; }
        this.selectedCase.internalCase.providerId = provider.internalId;
        this.selectedCase.updateMode = AUTHORIZATION_UPDATE_MODE;
        this.updateCase(this.selectedCase, ASSIGN_TO_COACH, fromModal);
    };
    CaseManagementService.prototype.assignToCallCenter = function (provider, callCenterScheduled, sessionNotes) {
        var _this = this;
        this.caseService.getCaseById(this.selectedCase.id).subscribe(function (cases) {
            if (!cases) {
                return;
            }
            _this.selectedCase = cases;
            _this.selectedCase.internalCase.providerId = provider.internalId;
            _this.selectedCase.isCallCenter = callCenterScheduled;
            _this.selectedCase.updateMode = AUTHORIZATION_UPDATE_MODE;
            if (sessionNotes) {
                _this.selectedCase.sessionNotes = sessionNotes;
            }
            _this.updateCase(_this.selectedCase, ASSIGN_TO_CALL_CENTER);
        });
    };
    CaseManagementService.prototype.switchProvider = function (reason, options) {
        var _this = this;
        this.caseService.getCaseById(this.selectedCase.id).subscribe(function (cases) {
            if (!cases) {
                return;
            }
            _this.selectedCase = cases;
            _this.selectedCase.updateMode = SWITCH_COUNSELORS_UPDATE_MODE;
            if (_this.selectedCase.modeOfHelp !== BH_MODE_OF_HELP &&
                _this.selectedCase.providerId) {
                _this.selectedCase.providerId = '';
            }
            _this.selectedCase.reasonForSwitch = reason;
            if (options.selectState) {
                _this.selectedCase.selectState = options.selectState;
            }
            else {
                _this.selectedCase.counselingFor.participants = options.counselingFor;
                _this.selectedCase.counselingLocation = {
                    selection: COUNSELING_LOCATION_IN_OFFICE,
                    value: options.radius,
                    zip: options.zipCode,
                };
            }
            _this.updateCase(_this.selectedCase, SWITCH_PROVIDER);
        });
    };
    CaseManagementService.prototype.switchToInPerson = function (reason, zipCode, radius, counselingFor) {
        var _this = this;
        this.caseService.getCaseById(this.selectedCase.id).subscribe(function (cases) {
            if (!cases) {
                return;
            }
            _this.selectedCase = cases;
            _this.selectedCase.updateMode = IN_PERSON_UPDATE_MODE;
            _this.selectedCase.reasonForSwitch = reason;
            _this.selectedCase.counselingFor.participants = counselingFor;
            _this.selectedCase.counselingLocation = {
                selection: COUNSELING_LOCATION_IN_OFFICE,
                value: radius,
                zip: zipCode,
            };
            _this.updateCase(_this.selectedCase, SWITCH_TO_IN_PERSON);
        });
    };
    CaseManagementService.prototype.switchToPhysician = function (reason, zipCode, radius, selectedState) {
        var _this = this;
        this.caseService.getCaseById(this.selectedCase.id).subscribe(function (cases) {
            if (!cases) {
                return;
            }
            _this.selectedCase = cases;
            _this.selectedCase.updateMode = SWITCH_TO_PHYSICIAN_UPDATE_MODE;
            _this.selectedCase.reasonForSwitch = reason;
            _this.selectedCase.counselingLocation = {
                selection: COUNSELING_LOCATION_IN_OFFICE,
                value: radius || String(_this.selectedCase.counselorPreference.radius) || '50',
                zip: zipCode || _this.selectedCase.counselorPreference.zipCode,
            };
            _this.selectedCase.selectState = selectedState;
            _this.updateCase(_this.selectedCase, SWITCH_TO_PHISICIAN);
        });
    };
    CaseManagementService.prototype.changeToOnlineTherapy = function (reason, selectState, counselingFor) {
        var _this = this;
        this.caseService.getCaseById(this.selectedCase.id).subscribe(function (cases) {
            if (!cases) {
                return;
            }
            _this.selectedCase = cases;
            _this.selectedCase.updateMode = ONLINE_UPDATE_MODE;
            _this.selectedCase.selectState = selectState;
            _this.selectedCase.counselingFor.participants = counselingFor;
            _this.selectedCase.counselingLocation.selection =
                COUNSELING_LOCATION_IN_OFFICE;
            _this.selectedCase.reasonForSwitch = reason;
            _this.updateCase(_this.selectedCase, SWITCH_TO_ONLINE);
        });
    };
    CaseManagementService.prototype.changeVideoToOnlineTherapy = function (reason, selectState) {
        var _this = this;
        this.caseService.getCaseById(this.selectedCase.id).subscribe(function (cases) {
            if (!cases) {
                return;
            }
            _this.selectedCase = cases;
            _this.selectedCase.updateMode = ONLINE_UPDATE_MODE;
            _this.selectedCase.reasonForSwitch = reason;
            _this.selectedCase.selectState = selectState;
            _this.updateCase(_this.selectedCase, SWITCH_VID_TO_ONLINE);
        });
    };
    CaseManagementService.prototype.shouldShowEmployeeNumberStep = function (companyCode) {
        return __awaiter(this, void 0, void 0, function () {
            var companyCodeRequiresEmployeeNumber, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companyCodeRequiresEmployeeNumber(companyCode)];
                    case 1:
                        companyCodeRequiresEmployeeNumber = _a.sent();
                        return [4 /*yield*/, this.getUser().pipe(take(1)).toPromise()];
                    case 2:
                        user = _a.sent();
                        return [2 /*return*/, !user.employeeNumber && companyCodeRequiresEmployeeNumber];
                }
            });
        });
    };
    CaseManagementService.prototype.companyCodeRequiresEmployeeNumber = function (companyCode) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, (companyCode.toLowerCase() === EMPLOYEE_NUMBER_COMPANY_CODE.toLowerCase())];
            });
        });
    };
    CaseManagementService.prototype.setLoginStatus = function (loginStatus) {
        this.loginStatusChangeSubject.next(loginStatus);
    };
    CaseManagementService.prototype.getPfStatus = function () {
        var authId = this.selectedCase.internalCase.authorizationId;
        return this.caseService.getPfStatus(authId);
    };
    CaseManagementService.prototype.shouldLimitCases = function (caseData) {
        if (caseData === void 0) { caseData = null; }
        var passedCaseData;
        if (!caseData) {
            passedCaseData = this.intakeFlowService.getFormData();
        }
        else {
            passedCaseData = caseData;
        }
        var contractId = passedCaseData.currentContract.id, organizationId = passedCaseData.organization.id;
        return this.caseService.getShouldLimit(contractId, organizationId);
    };
    CaseManagementService.prototype.updateCase = function (caseData, event, fromModal) {
        var _this = this;
        this.spinUp(this.caseService
            .updateCaseById(caseData.id, caseData)
            .toPromise()
            .then(function (updated) {
            _this.analyticsService.trackEvent(event);
            upsertItem([updated], _this.openCasesSubject); // upserting item
            _this.refreshOpenCasesOnly();
            if (event === ASSIGN_TO_PROVIDER) {
                if (fromModal) {
                    _this.router.navigate(['/pages/dashboard']);
                }
                else {
                    if (_this.selectedCase.counselorPreference.modality !==
                        'onsiteCounseling') {
                        _this.router.navigate(['/pages/dashboard/counselor-contact']);
                    }
                }
            }
            else if (event === ASSIGN_TO_COACH) {
                fromModal
                    ? _this.router.navigate(['/pages/dashboard'])
                    : _this.router.navigate(['/pages/dashboard/counselor-contact']);
            }
            else if ([SWITCH_PROVIDER, SWITCH_TO_IN_PERSON, SWITCH_TO_PHISICIAN, SWITCH_TO_ONLINE].includes(event)) {
                _this.router.navigate(["/pages/counselor-faceted-search/" + caseData.id]);
            }
            else {
                _this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
                _this.router.onSameUrlNavigation = 'reload';
                _this.router.navigate(['/pages/intake/counselor-selection/${this.selectedCase.id}']);
            }
        }));
        this.spinner.load();
    };
    CaseManagementService.ngInjectableDef = i0.defineInjectable({ factory: function CaseManagementService_Factory() { return new CaseManagementService(i0.inject(i1.Router), i0.inject(i2.NbTokenStorage), i0.inject(i3.NbTokenService), i0.inject(i4.UserService), i0.inject(i5.CaseService), i0.inject(i6.ContractService), i0.inject(i7.OrgService), i0.inject(i8.IntakeFlowService), i0.inject(i9.CounselorService), i0.inject(i10.ContentManagementService), i0.inject(i11.AnalyticsService), i0.inject(i12.NbSpinnerService), i0.inject(i13.CookieService)); }, token: CaseManagementService, providedIn: "root" });
    return CaseManagementService;
}());
export { CaseManagementService };
export { ɵ0, ɵ1 };
