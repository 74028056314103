export const FIRST_RESPONDERS_IDENTIFIER = ['first responder', 'Sworn'];
export const PHYSICIAN_COUNSELORS_IDENTIFIER = ['physician'];
export const STRESS = 'Stress';
export const ANXIETY_STRESS = 'Anxiety/Stress';
export const ANXIETY_STRESS_COMMA = 'Anxiety,Stress';

export const ONLINE_UPDATE_MODE = 'CONVERT';
export const IN_PERSON_UPDATE_MODE = 'CONVERT TO IN-PERSON';
export const SWITCH_COUNSELORS_UPDATE_MODE = 'SWITCHING PROVIDER';
export const SWITCH_TO_PHYSICIAN_UPDATE_MODE = 'CONVERT TO PHYSICIAN COUNSELOR';
export const AUTHORIZATION_UPDATE_MODE = 'AUTHORIZATION';
export const SWITCH_TO_IN_PERSON = 'Switching to In-Person from Online';
export const SWITCH_TO_ONLINE = 'Switching to online counseling';
export const SWITCH_TO_PHISICIAN = 'Switching to Physician counseling';
export const SWITCH_PROVIDER = 'Switching providers';
export const SWITCH_VID_TO_ONLINE = 'Updating video to online counseling';
export const ASSIGN_TO_PROVIDER = 'Counseling in-office completed';
export const ASSIGN_TO_COACH = 'Coaching completed';
export const ASSIGN_TO_CALL_CENTER = 'Counseling video to be scheduled';
export const SUCCESSFUL_MODAL = 'Yes';
export const IN_PERSON_MODE_OF_HELP = 'in-person';
export const IN_PERSON_TEST_MODE_OF_HELP = 'inPerson';
export const ONLINE_MODE_OF_HELP = 'online';
export const FIRST_RESP_MODE_OF_HELP = 'first-responder';
export const COUNSELING_LOCATION_IN_OFFICE = 'In-Office';
export const FIRST_RESP_MODAL_HEADING =
  'Switching to another First Responder Counselor?';
export const ONLINE_SWITCH_MODAL_HEADING = 'Switching to Local Counseling?';
export const COACHING = 'These are your top 3 matching coaches';
export const INPERSON = 'These are your top 3 matching counselors';
export const ONLINE =
  'These are your top 3 matching BetterHelp (Online) counselors';
export const FR = 'These are your top 3 matching First Responder counselors';
export const FR_MODE_OF_HELP = 'first-responder';
export const BH_MODE_OF_HELP = 'online-therapy';
export const PHYSICIAN_MODE_OF_HELP = 'physician-counseling';
export const LOCAL_COUNSELING_MODE_OF_HELP = 'counselor';
export const COACHING_MODE_OF_HELP = 'coaching';
export const SELECT_COUNSELOR_MODES_OF_HELP = [FR_MODE_OF_HELP, BH_MODE_OF_HELP, LOCAL_COUNSELING_MODE_OF_HELP, PHYSICIAN_MODE_OF_HELP];
export const EM_LIFE_MODALITY = 'eM Life';
export const COACHING_MODALITY = 'counselor';
export const PHYSICIAN_MODALITY = 'physician';
export const ONSITE_COUNSELING = 'Onsite Counseling';
export const ONLINE_COUNSELING = 'BetterHelp (Online) Counseling';
export const FR_COUNSELING = 'First Responder Counseling';
export const PHYSICIAN_COUNSELING = 'Physician Counseling';
export const UNKNOWN_CASE = 'Unknown case type';
export const ONSITE_MODALITY = 'onsiteCounseling';
export const INPERSON_TITLE = 'Local Counseling';
export const INPERSON_MODEOFHELP = 'counseling';
export const GUIDED_MINDFULNESS_DASHBOARD_LABEL = 'eM Life Mindfulness App';
export const PERSONAL_COACHING_SERVICE_USED = 'Personal Coaching';

export const LOCATIONTYPES = {
  ONSITE: 'On-Site',
  INOFFICE: 'In-Office',
};

export const NO_PROVIDERS_MESSAGE =
  'We were not able to find a counselor in your area. Please adjust filters and try again.';
export const NO_PROVIDERS_MESSAGE_NO_FILTERS =
  'We were not able to find a counselor in your area.';
export const COUNSELING_FOR_INDIVIDUAL = 'Individual';
export const CARD_LIMIT = 3;

export const COUNSELING_CASE_LIMIT_STRING_NO_LINK = 'You have already created two counseling cases, which is your benefit limit. View the counseling cases you have created on your';


export const COACHING_CASE_LIMIT_STRING_NO_LINK = 'You have already created a Personal Coaching Case. Contact your Coach if you have unused coaching sessions. To verify your eligibility for additional sessions, ';

export const PLEASE_CALL_STRING = 'Please call 1-800-344-4222 for assistance.';

/* Services Offered */
export const COUNSELING_SERVICE = 'Counseling';
export const FR_SERVICE = 'First Responder';
export const BH_SERVICE = 'BetterHelp';
export const LOCAL_SERVICE = 'Local'
export const COACHING_SERVICE = 'Coaching';
export const GUIDED_MINDFULNESS_SERVICE = 'Guided Mindfulness App';
export const CLIENT_CONSULTATION_SERVICE = 'Client Consultation';
export const SELF_HELP_SERVICE = 'Self Help';
export const ONLINE_COUNSELING_SERVICE = 'Online Counseling';
export const PARENT_COACHING_SERVICE = 'Parent Coaching';
export const LEGAL_SERVICE = 'Legal';
export const FINANCIAL_SERVICE = 'Financial';
export const PARENT_AND_CHILDCARE_SERVICE = 'Parenting and Childcare Resources';
export const BABY_KIT_SERVICE = 'New Baby Kit';
export const ADULT_CARE_SERVICE = 'Adult Care Resources';
export const CHILDCARE_SERVICE = 'Childcare Coaching';
export const EMERGENCY_SERVICE = 'Emergency Services';
export const ENHANCED_SUPPORT_SERVICE = 'Enhanced Support';
export const IMMEDIATE_SUPPORT_SERVICE = 'Immediate Support';
export const PROVIDER_CONNECT_SUPPORT_SERVICE = 'Provider Connect';
export const PHYSICIAN_COUNSELING_SERVICE = 'Physician Counseling';

// Distance options
export const IN_PERSON_MILES = ['1', '2', '3', '4', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50'];
export const FR_MILES = ['10', '15', '20', '25', '30', '35', '40', '45', '50'];

// URL LINKS

export const COACHING_VIDEO_LINK = 'https://www.youtube.com/watch?v=N9iGH6l4pcM';
